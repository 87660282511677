import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

// Seting in .env(.prod) file
axios.defaults.baseURL = location.protocol + '//' + location.hostname + '/api'

export default new Vuex.Store({
  state: {
    user: null,
    patient: null,
    mppId: 0,
    search: {search: '', list: []},
    file: null,
    research: null,
    stfile: null,
    contracts: {org_id: 0, contract_list: [], garant_letter_list: []},
    services: {mpp_id: 0, list: []},
    statcards: {mpp_id: 0, list: []},
    protocols: {mpp_id: 0, list: []},
    researches: {mpp_id: 0, list: []},
    stprotocols: {mpp_id: 0, list: []},
    // Вкладка где будут хранится отобранные услуги,приемы, протоколы
    selectedByExpert: [],
    selectedStatcards: []
  },
  mutations: {
    setUserData(state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
    },
    setSearchData(state, searchData) {
      state.search = searchData
      localStorage.setItem('search', JSON.stringify(searchData))
    },
    setFileData(state, fileData) {
      state.file = fileData
      localStorage.setItem('file', JSON.stringify(fileData))
    },
    setResearchData(state, researchData) {
      state.research = researchData
    },
    setStfileData(state, stfileData) {
      state.stfile = stfileData
      localStorage.setItem('stfile', JSON.stringify(stfileData))
    },
    setContractsData(state, contractsData) {
      state.contracts = contractsData
      localStorage.setItem('contracts', JSON.stringify(contractsData))
    },
    setPatientData(state, patientData) {
      state.mppId = patientData.selectedPatient.mpp_id
      state.patient = patientData.selectedPatient
      state.selectedByExpert = []
      localStorage.setItem('mppId', JSON.stringify(patientData.selectedPatient.mpp_id))
      localStorage.setItem('patient', JSON.stringify(patientData.selectedPatient))
    },
    setServicesData(state, servicesData) {
      state.services = servicesData
      localStorage.setItem('services', JSON.stringify(servicesData))
    },
    setStatcardsData(state, StatcardsData) {
      state.statcards = StatcardsData
      localStorage.setItem('statcards', JSON.stringify(StatcardsData))
    },
    setProtocolsData(state, protocolsData) {
      state.protocols = protocolsData
      localStorage.setItem('protocols', JSON.stringify(protocolsData))
    },
    setResearchesData(state, researchesData) {
      state.researches = researchesData
      localStorage.setItem('researches', JSON.stringify(researchesData))
    },
    setStprotocolsData(state, stprotocolsData) {
      state.stprotocols = stprotocolsData
      localStorage.setItem('stprotocols', JSON.stringify(stprotocolsData))
    },
    pushSelectedByExpertData(state, selectedByExpertData) {
      state.selectedByExpert.push(selectedByExpertData)
    },
    removeSelectedByExpertData(state, selectedByExpertDataRemove) {
      for (let i = 0; i < state.selectedByExpert.length; i++) {
        // if (JSON.stringify(state.selectedByExpert[i]) === JSON.stringify(selectedByExpertDataRemove)) {
        if (state.selectedByExpert[i].id === selectedByExpertDataRemove.id) {
          if (state.selectedByExpert[i].type === selectedByExpertDataRemove.type) {
            state.selectedByExpert.splice(i, 1)
          }
        }
      }
    },
    clearUserData() {
      localStorage.removeItem('user')
      localStorage.removeItem('patient')
      localStorage.removeItem('mppId')
      localStorage.removeItem('search')
      localStorage.removeItem('contracts')
      localStorage.removeItem('services')
      localStorage.removeItem('statcards')
      localStorage.removeItem('protocols')
      localStorage.removeItem('researches')
      localStorage.removeItem('stprotocols')
      localStorage.removeItem('file')
      localStorage.removeItem('stfile')
      location.reload()
    }
  },
  actions: {
    login({commit}, credentials) {
      return axios
        .post('/login', credentials)
        .then(({data}) => {
          commit('setUserData', data)
        })
    },

    logout({commit}) {
      commit('clearUserData')
    },

    file({commit}, credentials) {
      return axios
        .post('/file', credentials)
        .then(({data}) => {
          commit('setFileData', data)
        })
    },

    research({commit}, credentials) {
      return axios
        .post('/research', credentials)
        .then(({data}) => {
          commit('setResearchData', data)
        })
    },

    stfile({commit}, credentials) {
      return axios
        .post('/stfile', credentials)
        .then(({data}) => {
          commit('setStfileData', data)
        })
    },

    search({commit}, credentials) {
      return axios
        .post('/search', credentials)
        .then(({data}) => {
          commit('setSearchData', data)
        })
    },
    contracts({commit}, credentials) {
      return axios
        .post('/contracts', credentials)
        .then(({data}) => {
          commit('setContractsData', data)
        })
    },

    setPatient({commit}, credentials) {
      commit('setPatientData', credentials)
    },

    services({commit}, credentials) {
      return axios
        .post('/services', credentials)
        .then(({data}) => {
          commit('setServicesData', data)
        })
    },
    service({commit}, credentials) {
      return axios
        .post('/service', credentials)
        .then(({data}) => {
          // Положим полученные данные в state.selectedByExpert
          commit('pushSelectedByExpertData', {
            id: credentials.serviceId,
            type: credentials.type,
            list: data.list
          })

        })
    },
    statcards({commit}, credentials) {
      return axios
        .post('/statcards', credentials)
        .then(({data}) => {
          commit('setStatcardsData', data)
        })
    },
    statcard({commit}, credentials) {
      return axios
        .post('/statcard', credentials)
        .then(({data}) => {
          // Положим полученные данные в state.selectedByExpert
          commit('pushSelectedByExpertData', {
            id: credentials.statcardId,
            type: credentials.type,
            list: data.list
          })

        })
    },
    protocols({commit}, credentials) {
      return axios
        .post('/protocols', credentials)
        .then(({data}) => {
          commit('setProtocolsData', data)
        })
    },
    protocol({commit}, credentials) {
      return axios
        .post('/protocol', credentials)
        .then(({data}) => {
          // Положим полученные данные в state.selectedByExpert
          commit('pushSelectedByExpertData', {
            id: credentials.protocolId,
            type: credentials.type,
            list: data.list
          })

        })
    },
    researches({commit}, credentials) {
      return axios
        .post('/researches', credentials)
        .then(({data}) => {
          commit('setResearchesData', data)
        })
    },
    stprotocols({commit}, credentials) {
      return axios
        .post('/stprotocols', credentials)
        .then(({data}) => {
          commit('setStprotocolsData', data)
        })
    },
    stprotocol({commit}, credentials) {
      return axios
        .post('/stprotocol', credentials)
        .then(({data}) => {
          // Положим полученные данные в state.selectedByExpert
          commit('pushSelectedByExpertData', {
            id: credentials.stprotocolId,
            type: credentials.type,
            list: data.list
          })

        })
    },
    addSelectedByExpert({commit}, credentials) {
      commit('pushSelectedByExpertData', credentials)
    },
    removeSelectedByExpert({commit}, credentials) {
      commit('removeSelectedByExpertData', credentials)
    },
  },
  getters: {
    isLogged: state => !!state.user,
    mppId: state => state.mppId,
    USER: state => {
      return state.user;
    },
    PATIENT: state => {
      return state.patient;
    },
    SEARCH: state => {
      return state.search;
    },
    CONTRACTS: state => {
      return state.contracts;
    },
    SERVICES: state => {
      return state.services;
    },
    STATCARDS: state => {
      return state.statcards;
    },
    PROTOCOLS: state => {
      return state.protocols;
    },
    RESEARCHES: state => {
      return state.researches;
    },
    RESEARCH: state => {
      return state.research;
    },
    STPROTOCOLS: state => {
      return state.stprotocols;
    },
    FILE: state => {
      return state.file;
    },
    STFILE: state => {
      return state.stfile;
    },
    SELECTEDBYEXPERT: state => {
      return state.selectedByExpert;
    },
  },
  modules: {}
})
