import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  created () {
      localStorage.removeItem('search')

      const contractsInfo = localStorage.getItem('contracts')
      if (contractsInfo) {
          const contractsData = JSON.parse(contractsInfo)
          this.$store.commit('setContractsData', contractsData)
      }

      const userInfo = localStorage.getItem('user')
      if (userInfo) {
          const userData = JSON.parse(userInfo)
          this.$store.commit('setUserData', userData)
      }


      const patientInfo = localStorage.getItem('patient')
      if (patientInfo) {
          const patientData ={selectedPatient:''}
          patientData.selectedPatient = JSON.parse(patientInfo)
          this.$store.commit('setPatientData', patientData)
      }

      axios.interceptors.response.use(
          response => response,
              error => {
              if (error.response.status === 401) {
                  this.$store.dispatch('logout')
              }
              return Promise.reject(error)
          })
  },
  render: h => h(App)
}).$mount('#app')
