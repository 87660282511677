<template>
  <div class="home">
    <TopMenu msg="ЛК эксперта страховой компании" />
  </div>
</template>

<script>
// @ is an alias to /src
import TopMenu from '@/components/TopMenu.vue'

export default {

    name: 'Home',
    components: {
        TopMenu
    }
}

</script>
