<template>
  <div>
    <b-navbar
      toggleable="lg"
      type="light"
      variant="light"
    >
      <b-navbar-brand href="/">
        <img
          alt="Medicina.ru"
          height="68px"
          src="../assets/med_logo.png"
        >
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"/>

      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav>
          <b-nav-item
            href="/cabinet"
            v-if="isLogged"
          >
            Кабинет эксперта (<em>{{ USER.organization_name }}</em>)
          </b-nav-item>
          <b-nav-item
            href="/login"
            v-else
          >
            Вход
          </b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" left>
          <b-nav-item-dropdown
            right
            v-if="isLogged"
          >
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <em>{{ USER.user.expert_name }}</em>
            </template>
            <b-dropdown-item
              @click="logout()"
              href="#"
            >
              Выход
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-form
      @reset="onReset"
      @submit.prevent="search"
      v-if="isLogged"
    >
      <b-container class="bv-example-row">
        <b-row align-v="center">
          <b-col cols="2">
            <label for="date_begin">Прикрепления:</label>
          </b-col>
          <b-col cols="3">
            <b-form-datepicker
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              class="mr-xl-2"
              id="date_begin"
              label-no-date-selected="Дата начала"
              locale="ru"
              v-model="date_begin"
            />
          </b-col>
          <b-col cols="3">
            <b-form-datepicker
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              class="mr-xl-2"
              id="date_end"
              label-no-date-selected="Дата окончания"
              locale="ru"
              v-model="date_end"
            />
          </b-col>
          <b-col>
            <b-form-input
              class="mr-xl-2"
              name="cardnum"
              placeholder="Поиск по номеру истории болезни"
              size="xl"
              type="text"
              v-model="cardnum"
            />
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="2">
            <label :for="contract_id">Договор/Гар. письмо: </label>
          </b-col>
          <b-col cols="3">
            <b-form-select
              :options="CONTRACTS.contract_list"
              class="mr-xl-2"
              name="contract_id"
              size="xl"
              v-model="contract_id"
            />
          </b-col>
          <b-col cols="3">
            <b-form-select
              :options="CONTRACTS.garant_letter_list"
              class="mr-xl-2"
              name="garant_letter_id"
              size="xl"
              v-model="garant_letter_id"
            />
          </b-col>
          <b-col>
            <b-button
              class="my-2 my-sm-0"
              size="sm"
              type="submit"
              variant="outline-primary"
            >
              Фильтр
            </b-button>
            |
            <b-button
              class="my-2 my-sm-0"
              size="sm"
              type="reset"
              variant="outline-secondary"
            >
              Сбросить фильтр
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <b-badge :variant=totalLabel.type pill>{{ totalLabel.str }}</b-badge>
    <h5>{{ msg }}</h5>
  </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'TopMenu',
        props: {
            msg: {
                type: String,
                required: false,
                default: ''
            },
        },
        data() {
            return {
                date_begin: null,
                date_end: null,
                cardnum: '',
                contract_id: 0,
                garant_letter_id: 0,
                error: '',
            }
        },
        computed: {
            ...mapGetters(
                [
                    'isLogged', 'USER', 'SEARCH', 'CONTRACTS'
                ]),
            totalLabel() {
                var label = {str: '', type: ''};
                if (this.$route.path !== '/info') {
                    if (this.SEARCH.total > 1000) {
                        label.str = 'Показано первые 1000 прикреплений из ' + this.SEARCH.total + ', примените фильтры для показа нужных пациентов'
                        label.type = 'warning'
                    } else {
                        label.str = 'Всего прикреплений: ' + this.SEARCH.total + '';
                        label.type = 'light'
                    }

                }
                return label
            }
        },
        created() {
            this.initdropdown();
            if (this.SEARCH.list.length == 0) {
                this.search()
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout')
            },
            search() {
                this.$store
                    .dispatch('search', {
                        cardnum: this.cardnum,
                        contract_id: this.contract_id,
                        garant_letter_id: this.garant_letter_id,
                        date_begin: this.date_begin,
                        date_end: this.date_end
                    })
                    .then(() => {
                        //
                        //https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
                        //this.$router.push({name: 'Cabinet'},() => {})

                        this.garant_letter_id = this.SEARCH.garant_letter_id
                        this.contract_id = this.SEARCH.contract_id
                        this.date_begin = this.SEARCH.date_begin
                        this.date_end = this.SEARCH.date_end
                        this.cardnum = this.SEARCH.search
                        if (this.$route.path != '/cabinet') {
                            this.$router.push({name: 'Cabinet'})
                        }
                    })
                    .catch(err => {
                        this.error = 'Укажите корректный номер'
                        console.log(err)
                    })
            },
            initdropdown() {
                //TODO если уже есть договора - не запрашивать от API - только при принудительном обновлении
                this.$store
                    .dispatch('contracts', {})
                    .then(() => {
                        // Обновить дропдауны?
                    })
                    .catch(err => {
                        //this.error = 'Ошибка получения данных для дропдаунов'
                        console.log(err)
                    })

            },
            onReset(evt) {
                evt.preventDefault()
                // Reset our form values
                this.date_begin = null
                this.date_end = null
                this.cardnum = ''
                this.contract_id = 0
                this.garant_letter_id = 0
                this.search();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #nav {
    padding: 30px;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }
</style>
